
.caption-header {
  font-size: 1.2em;
  color: $cs-white;
  background: $cs-gray-mid-3;

  th {
    //padding: 8px 10px !important;
    //text-align: center;
  }

  &.caption-music {
    background: $cs-caption-music !important;
  }

  &.caption-show {
    background: $cs-caption-show !important;
  }

  &.caption-overall-show {
    background: $cs-caption-show !important;
  }

  &.caption-combo {
    background: $cs-caption-combo !important;
  }

  &.caption-visual-performance {
    background: $cs-caption-combo !important;
  }

  &.caption-place {
    background: $cs-gray-mid-3 !important;
  }
}

.caption-raw-score {
  font-size: 1em;
  //color: $cs-white;
  background: lighten($cs-gray-mid-3, 5);

  /*&.caption-music {
    background: lighten($cs-caption-music, 15) !important;
  }

  &.caption-show {
    background: lighten($cs-caption-show, 5) !important;
  }


  &.caption-overall-show {
    background: $cs-caption-show !important;
  }

  &.caption-combo {
    background: lighten($cs-caption-combo, 5) !important;
  }

  &.caption-visual-performance {
    background: $cs-caption-combo !important;
  }

  &.caption-place {
    background: $cs-gray-mid-3 !important;
  }*/
}

.caption-weighted-score {
  font-size: 1em;
  //color: $cs-white;
  background: lighten($cs-gray-mid-3, 10);

  /*&.caption-music {
    background: lighten($cs-caption-music, 10) !important;
  }

  &.caption-show {
    background: lighten($cs-caption-show, 10) !important;
  }

  &.caption-combo {
    background: lighten($cs-caption-combo, 10) !important;
  }

  &.caption-overall-show {
    background: lighten($cs-caption-show, 5) !important;
  }


  &.caption-visual-performance {
    background: lighten($cs-caption-combo, 5) !important;
  }

  &.caption-place {
    background: lighten($cs-gray-mid-3, 5) !important;
  }*/
}

.caption-rank {
  font-size: 1.1em;
  //color: $cs-white;
  background: lighten($cs-gray-mid-3, 5);

  /*&.caption-music {
    background: darken($cs-caption-music, 5) !important;
  }

  &.caption-show {
    background: darken($cs-caption-show, 5) !important;
  }

  &.caption-combo {
    background: darken($cs-caption-combo, 5) !important;
  }

  &.caption-overall-show {
    background: darken($cs-caption-show, 5) !important;
  }


  &.caption-visual-performance {
    background: darken($cs-caption-combo, 5) !important;
  }

  &.caption-place {
    background: darken($cs-gray-mid-3, 5) !important;
  }*/
}

.total-score {
  font-size: 1.3em;
  border-top: 4px solid;
}

.total-rank {
  font-size: 1.2em;
}

.penalty-note {
  font-size: .75em;
  font-weight: 400;
}

$i: 0;
@while $i <= 10 {
  .score-gradient-#{$i * 10} {
    $v: 100 - ($i * 12);

    @if $i > 5 {
      //color: $cs-white;
    }

    //background-color: mix($cs-green, $cs-gray-light, $i * 10);


    $i: $i + 0.5;
  }
}

.missing-score {
  background: $cs-red;
  color: $cs-white;
}


input.score {
  margin: -8px;
  padding: 8px;
  text-align: center;
  border: 0;
  background: none;

  &:focus, &.focus {
    background: lighten($cs-green-lighter,10);
  }

  &.saving {
    background: $cs-green-lighter;
  }

  &.saved {
    //background: lighten($cs-blue,40);
    //padding-right: 4px;
    //border-right: 4px solid lighten($cs-blue,10);
    color: $cs-blue;
    font-weight: bold;
  }

  &.error {
    background: lighten($cs-red, 10);
  }
}

.score {
  //border: 1px solid #ddd;
  padding: 0 3px;

  &.raw {
    //display: none;
  }
  &.weighted {

  }
  &.penalty {
    color: $cs-red;

    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
  &.rank {
    font-weight: bold;
  }
  &.total {
    font-weight: bold;
  }
  &.subtotal {
    color: $cs-gray-mid-3;
  }
  &.tied {
    white-space: nowrap;
  }
  &.tied:after {
    content: 'tied';
    display: inline-block;
    padding: 0 4px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    border-radius: 4px;
    background: #ee191c;
  }
}
