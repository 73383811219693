//@import 'variables';

table.spreadsheet-legend {
  border: 1px solid #ccc;
  margin-bottom: 15px;
  font-size: 13px;
  
  th {
    padding: 10px;
    font-size: 1.1em;
  }
  td {
    padding: 8px 10px;
  }

  .missing-score {

  }
  .score-in-focus {
    background: lighten($cs-green, 20);
  }
  .saved-score {

  }

}

.scorecard {
    background: #eee;
    padding: 10px 5px;

    @media only screen and (min-width: 768px) {
      padding: 15px;
    }


    .caption-container {

      .caption-heading {
          border-bottom: 2px solid;
          font-size: 2em;
          margin-bottom: 15px;
          padding: 5px 13px;
      }

    }

    .criterion-container {
        background: #fff;
        border: 1px solid #ddd;
        margin-bottom: 5px;
        overflow: hidden;
        padding: 10px 15px;

        &:hover, &:focus, &:active {
          .number-selector {
            display: block;
          }
        }

        .criterion {
            //float: left;
            height: 44px;
            line-height: 32px;
            margin-right: 15px;
            width: auto;
            font-size: 18px;

        }

        .criterion-description {
          margin-bottom: 20px;
        }

        .score {
            float: left;
            border: 0;
            padding: 0;
            margin-bottom: 20px;

            input {
                padding: 5px;
                text-align: center;
                width: 90px;
                height: 44px;
                font-size: 20px;
                border: 3px solid #ccc;
                //transition: background-color 0.2s ease;

                &.updating {
                  background: lighten($cs-green, 20);

                }

                &.missing-score {
                  border-color: $cs-red;
                  background: lighten($cs-red, 40);
                }
            }

        }

        .number-selector-container {
          float: right;
          max-width: 600px;
        }
    }

    .submit-container {
      margin-top: 20px;
      text-align: center;
    }
}



.popup-input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  background: #ccc;
  width: 610px;
  max-width: 100%;
  display: none;

  &.active {
    display: block;
  }
}


.number-selector {
    float: right;
    margin: -4px 0 0 0;
    padding: 0;
    //display: none;

    &.half {
      margin-right: 28px;
      margin-top: 0;
      font-size: 0.8em;
    }

    + .number-selector {
      clear: both;
    }

    li {
        display: inline-block;
        margin: 4px;
        position: relative;

        a {
            border: 2px solid #eee;
            border-radius: 5px;
            display: block;
            height: 44px;
            line-height: 44px;
            position: relative;
            text-align: center;
            width: 44px;
            color: #777;
            background: $cs-white;

            &.current {
              background: $cs-green;
              color: #fff;
              font-weight: bold;
              text-decoration: none;
            }

            &:hover {
              background: $cs-green-lighter;
              color: #fff;
              text-decoration: none;
            }

            &:active {
              background: $cs-green-lighter;
              color: #fff;
              text-decoration: none;
            }
        }
    }
}
