@import "variables";
@import "dynamic-colors";
@import "bootstrap-custom";
@import "~sass-mediaqueries/media-queries";
@import "./vendor/jquery.timepicker";

@import "color-swatches";
@import "scorecard";
@import "scoreboard";
@import "tabs";
@import "tables";
@import "actions";
@import "lists";
@import "labels";
@import "forms";
@import "headings";
@import "content";
@import "schedule";
@import "spacing";
@import "director-form";
@import "board";
@import "public";
@import "ss_helper";


.alert {
  border-left-width: 6px;
}

a.active {
  font-weight: bold;
}
.body-width {
  max-width: $cs-body-width;
  margin: auto;
  width: auto;
  box-sizing: border-box;
}


.competition-header-wrap {

  background: $cs-primary;
  color: $cs-white;

  .competition-header {

    padding: $cs-padding;
    padding-bottom: $cs-padding + 20;
    overflow: hidden;

    .heading {
      float: left;
      text-transform: uppercase;
    }

    .subheading {
      float: right;
    }
  }

}

.competition-content-wrap {
  top: -20px;
  overflow: hidden;
  position: relative;

  .competition-sidebar {
    max-width: 260px;
    width: 260px;
    float: left;
    padding: $cs-padding;
    background: $cs-gray-dark;
    color: $cs-white;

    .heading {
      margin-top: 0;
      text-transform: uppercase;
      border-bottom: 1px solid;
    }
  }

  .competition-content {
    padding: $cs-padding;
    margin-left: 260px;
    background: $cs-white;
  }
}

.content-wrap {

  .content {

  }
}



.subheading {
  display: block;
  color: #777;
}





.header-wrap {
  background: $cs-gray-dark;
  overflow: hidden;
  color: $cs-white;

  & + .header-wrap {
    border-top: 0;
    background: lighten($cs-gray-dark,10);
  }

  & + .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &.admin {
    margin-bottom: 0;
    border-top: 0;
    border-bottom: 0;
    //margin-bottom: -6px;

    .header .navigation li {
      a, &.title {
        padding: 15px;
      }
    }
  }

  .header {

    padding: 0;
    overflow: hidden;

    .navigation {
      float: left;
      margin: 0;
      padding: 0;
      font-size: 0;

      &.user-actions {
        float: right;
      }

      li {
        display: inline-block;
        font-size: 14px;

        &.title {
          //border: 1px solid;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: bold;
          padding: 25px 20px;
          color:  $cs-white;

          .intro {
            text-transform: none;
            font-size: 13px;
            font-weight: normal;
          }

          .organization-name {
            //text-decoration: underline;
            background: $cs-yellow;
            color: $cs-gray-dark;
            margin-left: 5px;
            padding: 5px 10px;
          }
        }

        a {
          display: block;
          //border: 1px solid;
          padding: 25px 15px;
          color:  $cs-white;

          &.active {
            background: lighten($cs-gray-dark,15);
          }

          &:hover {
            text-decoration: underline;
            background: lighten($cs-gray-dark,7);
          }
        }
      }
    }
  }


}

.competition-bar-wrap {
  background: $cs-white;
  //border-bottom: 1px solid $cs-gray-mid-1;
  margin-bottom: 10px;
  box-shadow: 0 0 2px rgba(0,0,0,0.5);

  .competition-bar {

    overflow: hidden;
    position: relative;

    .carmen-logo-wrap {
      float: right;
      padding: 20px;
      display: none;
    }

    .heading {

      float:  left;
      padding:  20px;
      font-size: 20px;
      line-height: 20px;

      .subheading {
        font-size: 18px;
        display: inline-block;
        margin-left: 5px;
      }
    }

    .status-container {
      float: right;
      padding: 20px 0;
    }
  }
}

.division-bar {

  //border: 1px solid #d8d8d8;
  background: lighten($cs-gray-dark, 15);
  color: $cs-white;
  overflow: hidden;
  clear: both;
  border-bottom-width: 4px;

  & .status {
    margin-left: 10px;
  }

  & a {
    color: $cs-white;
  }

  .heading {
    float: left;
    font-size: 24px;
    //line-height: 30px;
    padding: 20px;
    //border-top: 4px solid $cs-green;
  }

  .division-actions {
    display: block;
    float: right;
    padding: 20px;
  }
}

.division-navigation-bar {
  background: #f3f3f3;
  border: 1px solid #d8d8d8;
  padding: 15px;
  padding-bottom: 0;
  margin-top: -1px;

  .division-navigation {

    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    margin-bottom: -1px;
    white-space: nowrap;
    overflow-x: auto;

    li {

      display:  inline-block;
      float: left;

      &.scoring {
        float: right;

        a {
          background: $cs-status-active;
          color: $cs-white;
          font-weight: bold;
          border-top: 1px solid darken($cs-status-active, 10);
          border-right: 1px solid darken($cs-status-active, 10);
          border-left: 1px solid darken($cs-status-active, 10);

          &:hover {
            background: darken($cs-status-active, 10);
            color: $cs-white;
            border-top-color: darken($cs-status-active, 10);
          }
        }
      }

      a {
        padding: 10px 15px;
        border: 1px solid #d8d8d8;
        background: #eee;
        display: block;
        color: $cs-gray-dark;

        .count {
          color: $cs-white;
          background: lighten($cs-gray-dark, 30);
          border-radius: 5px;
          font-size: 11px;
          padding: 2px 4px;
          margin-left: 5px;
          font-weight: bold;
          display: inline;
        }

        &:hover {
          background: $cs-white;
          text-decoration: none;
          padding-top: 8px;
          border-top-width: 3px;
          border-top-color: lighten($cs-primary, 20);
        }

        &.active {
          background: $cs-white;
          border-top-color: $cs-primary;
          border-bottom-color: $cs-white;
          padding-top: 8px;
          border-top-width: 3px;
        }
      }
    }
  }
}

.add-judge {
  clear: both;
  padding: 15px;

  button {
    display: block;
    width: 100%;
  }
}

.add-rating {
  clear: both;
  padding: 15px;

  button {
    display: block;
    width: 100%;
  }
}

.remove-judge button{
    color: #a94442;
    border-color: #a94442;
    font-weight: 700;
}

.remove-rating {
  padding-top: 23px;

  button {
    /*background: #f2dede;*/
    color: #a94442;
    border-color: #a94442;
    font-weight: 700;
  }
}

.rating-system .alert {
  clear: both;
}

.round-navigation-bar {
  //background: #f3f3f3;
  border: 1px solid #d8d8d8;
  padding: 15px;
  margin-top: -1px;

  .round-status {
    float: right;
    padding: 10px;
  }

  .round-navigation {

    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    margin-bottom: -1px;
    display:  inline-block;

    li {

      display:  inline-block;
      margin-right: 5px;

      &.round-active {

      }

      &.round-inactive {

      }


      a {
        padding: 10px 40px;
        border: 1px solid #d8d8d8;
        display: block;
        color: $cs-gray-dark;
        font-weight: bold;
        font-size: 1.2em;

        &:hover {
          background: $cs-white;
          text-decoration: none;
          border-left: 4px solid $cs-gray-mid-2;
          padding-left: 37px;
        }

        &.active {
          background: $cs-white;
          padding-left: 37px;
          border-left: 4px solid $cs-primary;
          //border-bottom-color: $cs-white;
          //border-top-color: $cs-primary;
        }


        .round-navigation-link-status {
            font-size: 0.7em;
            font-weight: normal;
            margin-left: 5px;
            border-radius: 5px;

        }
      }
    }
  }
}



.choir-bar {

  border-bottom: 1px solid #d8d8d8;
  overflow: hidden;
  clear: both;

  .heading {
    float: left;
    font-size: 24px;
    line-height: 30px;
    padding: 20px 10px;

    .subheading {
      font-size: 18px;
      display: block;
    }
  }

  .choir-actions {
    display: block;
    float: right;
    //padding: 20px 10px;
    margin-top: 40px;
  }
}


.content {
  border: 1px solid #d8d8d8;
  //border-top-width: 0;
  padding: 20px;
  //margin-bottom:  40px;
  //margin-top: 20px;
  margin-top: -1px;
  background: $cs-white;
  //box-shadow: 0 3px 3px rgba(0,0,0,0.3);

  &.collapse {
    //margin-top: -1px;
  }

  .content-header {

    display: block;
    overflow: hidden;
    border-bottom: 1px solid $cs-gray-mid-1;
    margin-bottom: 20px;

    h1, .heading {
      float: left;
      //font-size: 24px;
      //padding: 20px;
    }

    > .action {
      display: block;
      float: right;
      margin: 25px 0 15px;
    }

    .actions-group {
      display: block;
      float: right;
      padding: 25px 0 15px;
    }
  }

  // Clearfix
  &::after {
    content: "";
    clear: both;
    display: table;
  }

}



.content-subheader {

  display: block;
  overflow: hidden;
  border-bottom: 4px double $cs-gray-mid-1;
  margin-bottom: 20px;

  &.caption {
    color: $cs-white;
    padding: 10px;
    border: 0;
    margin-top: 40px;
    background: $cs-gray-mid-3;

    /*&.overall {
      background: $cs-caption-overall;
    }
    &.music {
      background: $cs-caption-music;
    }
    &.show {
      background: $cs-caption-show;
    }
    &.combo {
      background: $cs-caption-combo;
    }

    &.overall-show {
      background: $cs-caption-show;
    }

    &.visual-performance {
      background: $cs-caption-combo;
    }

    &.place {
      background: $cs-gray-mid-3;
    }*/

    & h2 {
      padding: 0;
      margin: 5px;
    }

    & .action {
      color: $cs-white;
      margin: 5px;
    }
  }

  h1, h2, .heading {
    float: left;
    display: inline;
    border: 0;
  }

  h2 {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  > .action {
    display: block;
    float: right;
    margin: 30px 0 10px;
  }

  .actions-group {
    display: block;
    float: right;
    padding: 25px 0 15px;
  }
}


.list-group {

  .list-group-item {
    overflow: hidden;
  }

  &.horizontal {

  	padding-left: 0;
    margin-top: 20px;

    &.full-width {
      display:  table;
      width: 100%;

      & .list-group-item {
        display: table-cell;
        text-align: center;
      }
    }

  	.list-group-item {
  		display: inline-block;
  		margin-bottom: 0;
  		margin-left:-4px;
  		margin-right: 0;
      border-left-width: 1px;
      border-right-width: 0;


  		&:first-child {
  			border-top-right-radius:0;
  			border-bottom-left-radius:4px;
  		}

  		&:last-child {
  			border-top-right-radius:4px;
  			border-bottom-left-radius:0;
        border-right-width: 1px;
  		}

      .attr_label {
      	font-size: 0.9em;
      }

      .attr_value {
      	margin-left: 5px;
      	font-size: 2em;
      }
  	}
  }

}

#user-person-filter-group {
  display: flex;
  margin: 20px 0;
  text-align: center;

  @media (min-width: $tablet-min) {
    display: inline-block;
  }

  a.user-person-filter {
    margin-bottom: 4px;
    font-size: 13px;
    padding: 6px;
    border-radius: 0;
    flex-grow: 1;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media (min-width: 380px) {
      font-size: 14px;
      padding: 6px 12px;
    }

    @media (min-width: $tablet-min) {
      border-radius: 4px;
    }

    &.active {
      font-weight: 400;
    }
  }
}

#user-person-search {
  position: relative;
  margin: 20px 0;

  @media (min-width: $tablet-min) {
    display: inline-block;
  }

  img {
    position: absolute;
    top: 11px;
    left: 10px;
    width: 16px;
    height: 16px;
    z-index: 1;
    opacity: .5;

    @media (min-width: $tablet-min) {
      left: 30px;
    }
  }

  input {
    position: relative;
    top: 2px;
    padding: 6px 12px 6px 36px;
    width: 100%;
    border: 1px #ccc solid;
    border-radius: 4px;

    @media (min-width: $tablet-min) {
      margin-left: 20px;
      width: 300px;
    }
  }
}

#user-person-list {
  border-top: 1px #ddd solid;

  &.users-only .person.non-user,
  &.non-users-only .person.user {
    display: none;
  }

  .person {
    position: relative;
    padding: 25px 20px;
    border: 1px #ddd solid;
    border-top-width: 0;

    @media (min-width: $tablet-min) {
      padding: 25px 30px;
    }

    &.even {
      background-color: #f9f9f9;
    }

    &.search-hidden {
      display: none;
    }

    .user-flag {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px 8px;
      width: 80px;
      border-bottom-left-radius: 8px;
      color: #ffffff;
      font-size: 13px;
      text-align: center;
    }

    &.user .user-flag {
      background: $cs-green;
    }

    &.non-user .user-flag {
      background: $cs-purple;
    }

    .name {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .user-blocks {

      @media (min-width: $tablet-min) {
        display: flex;
      }

      .user-details {
        line-height: 2;

        @media (min-width: $tablet-min) {
          padding: 0 30px;
          width: 70%;
        }

        .detail {
          margin: 5px 0;

          @media (min-width: $tablet-min) {
            display: flex;
          }

          .detail-label,
          .detail-value {
            display: block;
          }

          .detail-label {
            font-weight: 700;

            @media (min-width: $tablet-min) {
              width: 30%;
              min-width: 120px;
            }
          }

          .detail-value {
            line-height: 1.5;

            @media (min-width: $tablet-min) {
              width: 70%;
              min-width: 200px;
            }

            a {
              display: inline;
            }
          }

          &.roles .detail-value {
            line-height: 2;
          }

          .role {
            display: inline-block;
            padding: 4px 8px;
            margin: -4px 4px -4px 0;
            color: #ffffff;
            font-size: 13px;
            border-radius: 6px;
            background: #b84660;
            line-height: 1;

            &.user-role {
              background: $cs-green;
            }
          }
        }
      }

      .user-actions {
        @media (min-width: $tablet-min) {
          padding-left: 30px;
          width: 30%;
          border-left: 1px #ddd solid;
        }

        .user-actions-title {
          font-weight: 700;
          line-height: 2;
          margin: 5px 0;
        }

        a.btn.action {
          margin-top: -2px;
        }

        form {
          display: inline-block;
        }
      }
    }
  }
}

.retired-sheet {
  opacity: 0.5;
}

.breadcrumbs-footer {
  padding: 10px 0;
  ol.breadcrumb {
    margin: 0;
    background: none;
  }
}

.login-container {
  background: $cs-white;
  border: 1px solid $cs-gray-light;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  margin: 20px auto;

  .logo-container {
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $cs-gray-light;
  }
}

#autosave-alert-box {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  background: $cs-yellow;
  color: $cs-black;
}

.indented {
  padding-left: 30px;
}

// Tweak to the highlight class that appears in Selectize seach.
.selectize-dropdown [data-selectable] .highlight {
  display: inline-block;
}

.recording-wrapper {
  margin: 30px 0;
}

.record-row {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.record-item {
  padding: 10px 0 10px 20px;
  margin-bottom: 10px;

  .record-item-audio {
    vertical-align: middle;
    display: inline-block;
    text-align: center;

    audio {
      display: block;
    }
  }
}


.audio-recorder {
  position: relative;
  padding-top: 75px;
  min-width: 240px;
  line-height: 1;
  text-align: left;
  color: #ffffff;
  background: #4b5256;
  overflow: hidden;

  .ar-info {
    position: absolute;
    top: 0;
    right: 0;
    left: 75px;
    padding: 8px 4px 0 12px;
    transition: all 0.5s ease;

    .ar-title {
      position: absolute;
      top: 8px;
      left: 12px;
      right: 4px;
      height: 16px;
      font-weight: bold;
      font-size: 16px;
      overflow: hidden;
      transition: all 0.5s ease;
    }

    .ar-title-playback {
      position: absolute;
      top: 24px;
      left: 12px;
      right: 4px;
      height: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      transition: all 0.5s ease;
    }

    .ar-existing {
      position: absolute;
      top: 32px;
      left: 12px;
      right: 4px;
      font-size: 14px;
      color: #e5e5e5;
      margin-bottom: 5px;
      text-decoration: underline;
      cursor: pointer;

      &::after {
        content: ' ';
        display: inline-block;
        position: relative;
        top: -2px;
        margin-left: 5px;
        border-top: 5px #e5e5e5 solid;
        border-right: 6px transparent solid;
        border-left: 6px transparent solid;
        border-bottom: 0 #e5e5e5 solid;
        transition: all 0.5s ease;
      }
    }
  }

  .ar-playback-close {
    position: absolute;
    top: 6px;
    right: -36px;
    width: 24px;
    height: 24px;
    opacity: .9;
    cursor: pointer;
    /* Close icon from FontAwesome (https://fontawesome.com/license). Not modified except for color. */
    background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="window-close" class="svg-inline--fa fa-window-close fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23ffffff" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z"></path></svg>') no-repeat center center;
    transition: right 0.5s ease, opacity 0.25s ease;

    &:hover {
      opacity: 1;
    }
  }

  .ar-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
    height: 75px;
    border-right: 1px #808080 solid;
    overflow: hidden;
    transition: all 0.5s ease;

    button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background: #903d9a;
      border: none;
      transition: all 0.25s ease;

      .ar-control-symbol {
        display: block;
        position: absolute;
        top: 34px;
        left: 50%;
        margin: -10px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: #ffffff;
        transition: all 0.5s ease;
      }

      &:hover {
        background: #7F4091;
      }

      &::after {
        content: 'RECORD';
        display: block;
        position: absolute;
        bottom: 9px;
        left: 0;
        right: 0;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }

  &.recording .ar-control {
    button {
      background: #ee191c;

      .ar-control-symbol {
        border-radius: 0;
      }

      &:hover {
        background: #de2131;
      }

      &::after {
        content: 'STOP';
      }
    }
  }


  &.disabled .ar-control {
    button {
      background: transparent;
      cursor: not-allowed;

      .ar-control-symbol {
        opacity: .5;
      }
    }

    button::after {
      opacity: .5;
    }
  }

  .ar-progress {
    position: absolute;
    top: 58px;
    left: 75px;
    right: 0;
    height: 16px;
    background: #272B2D;
    box-sizing: content-box;
    border-top: 1px #808080 solid;
    transition: all 0.5s ease;

    .ar-meter-box {
      position: absolute;
      top: 5px;
      right: 44px;
      bottom: 5px;
      left: 5px;
      border: 1px #606060 solid;
      border-radius: 3px;

      .ar-meter-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #ee191c;
        border-radius: 3px;
        transition: all 0.25s ease;
      }

      .ar-meter-bar::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 3px;
        right: 0;
        width: 0;
        height: 0;
        border-radius: 5px;
        background: #ee191c;
        border: 0 rgba(255, 127, 127, 0) solid;
      }
    }

    .ar-progress-text {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 38px;
      font-size: 11px;
      text-align: center;
    }
  }


  &.recording .ar-progress {
    .ar-meter-box .ar-meter-bar {
      width: 100%;
    }

    .ar-meter-box .ar-meter-bar::after {
      top: -3px;
      right: -5px;
      width: 10px;
      height: 10px;
      border: 1px rgba(255, 127, 127, 1) solid;
      animation: ar_beacon_glow 1s alternate ease infinite;
    }
  }

  &.uploading .ar-progress {
    .ar-meter-box {
      right: 74px;
    }

    .ar-meter-box .ar-meter-bar {
      background: #0985eb;
    }

    .ar-progress-text {
      width: 68px;
    }
  }

  &.uploading.unknown .ar-progress .ar-meter-box .ar-meter-bar,
  &.deleting .ar-progress .ar-meter-box .ar-meter-bar {
    width: 100%;
    background: #0985eb;
    background-image: linear-gradient(
      -45deg,
      #0985eb 25%,
      #80c6ff 25%,
      #80c6ff 50%,
      #0985eb 50%,
      #0985eb 75%,
      #80c6ff 75%,
      #80c6ff
    );
    background-size: 50px 50px;
    animation: ar_stripe_motion 2s linear infinite;
  }

  &.playback {
    .ar-info {
      left: 0;
      right: 40px;

      .ar-title {
        height: 0;
      }

      .ar-title-playback {
        top: 8px;
        height: 16px;
      }
    }

    .ar-playback-close {
      right: 12px;
    }

    .ar-control {
      width: 0;
    }

    .ar-progress {
      left: 0;
    }
  }

  .ar-playlist {
    max-height: 0;
    overflow-y: scroll;
    font-size: 13px;
    transition: all 0.5s ease;

    ol {
      padding: 0;
      margin: 0;
      list-style: none;
      counter-reset: ar-playlist-counter;

      li {
        display: flex;
        align-items: center;
        position: relative;
        padding: 4px 4px 4px 38px;
        height: 50px;
        border-top: 1px #808080 solid;
        counter-increment: ar-playlist-counter;

        &:hover {
          background: #586065;
        }

        &::before {
          content: counter(ar-playlist-counter) ".";
          opacity: 0.5;
          font-weight: bold;
          position: absolute;
          top: calc(50% - 6.5px);
          width: 20px;
          text-align: right;
          left: 10px;
        }

        audio {
          display: none;
        }

        .ar-playlist-item-name {
          display: flex;
          flex-grow: 1;
          line-height: 1.2;
        }

        .ar-playlist-functions {
          display: flex;
          height: 32px;

          button, a {
            display: block;
            position: relative;
            margin-left: 3px;
            width: 32px;
            height: 32px;
            text-decoration: none;
            border: 1px #808080 solid;
            border-radius: 2px;
            background: #272B2D;
            transition: all 0.25s ease;

            &:active {
              background: #903d9a;
            }

            &:hover {
              background: #7F4091;
            }

            &.ar-playlist-play-pause {
              &::after {
                content: ' ';
                display: block;
                position: absolute;
                top: 10px;
                left: 10px;
                width: 12px;
                height: 12px;
                border-top: 6px transparent solid;
                border-right: 0 #ffffff solid;
                border-bottom: 6px transparent solid;
                border-left: 12px #ffffff solid;
              }

              &.active::after {
                top: 9px;
                left: 9px;
                width: 14px;
                height: 14px;
                border-top: 0 transparent solid;
                border-right: 5px #ffffff solid;
                border-bottom: 0 transparent solid;
                border-left: 5px #ffffff solid;
              }
            }

            &.ar-playlist-download {
              &::after {
                /* Download icon from FontAwesome (https://fontawesome.com/license). Not modified except for color. */
                content: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" class="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23ffffff" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>');
                position: absolute;
                top: 8px;
                left: 9px;
                width: 14px;
              }
            }

            &.ar-playlist-delete {
              &:hover {
                background: #ee191c;
              }

              &::after {
                /* Trash icon from FontAwesome (https://fontawesome.com/license). Not modified except for color. */
                content: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23ffffff" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg>');
                position: absolute;
                top: 8px;
                left: 9px;
                width: 12px;
              }
            }
              &.ar-file-delete {
                  &:hover {
                      background: #ee191c;
                  }

                  &::after {
                      /* Trash icon from FontAwesome (https://fontawesome.com/license). Not modified except for color. */
                      content: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23ffffff" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg>');
                      position: absolute;
                      top: 8px;
                      left: 9px;
                      width: 12px;
                  }
              }
          }
        }

        &.playing {
          color: #903d9a;
          background: #e5e5e5;
          border-color: #903d9a;

          .ar-playlist-functions {
            button.ar-playlist-play-pause {
              background: #903d9a;

              &:hover {
                background: #7F4091;
              }
            }
          }
        }

        &.deleting {
          color: #ee191c;
          background: #e5c0c0;
          border-color: #ee191c;

          .ar-playlist-functions {
            opacity: .5;
          }
        }
      }

      &.empty {
        li {
          padding-left: 18px;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  &.playlist-expanded {
  // &.playlist-expanded,
  // &[data-mode="player"] {
    .ar-info .ar-existing::after {
        border-top: 0 #e5e5e5 solid;
        border-bottom: 5px #e5e5e5 solid;
    }

    .ar-playlist {
      max-height: 175px;
    }

    &.tall-playlist .ar-playlist {
      max-height: 375px;
    }

    &[data-count="1"] .ar-playlist {
      max-height: 50px;
    }

    &[data-count="2"] .ar-playlist {
      max-height: 100px;
    }

    &[data-count="3"] .ar-playlist {
      max-height: 150px;
    }

    &[data-count="4"].tall-playlist .ar-playlist {
      max-height: 200px;
    }

    &[data-count="5"].tall-playlist .ar-playlist {
      max-height: 250px;
    }

    &[data-count="6"].tall-playlist .ar-playlist {
      max-height: 300px;
    }

    &[data-count="7"].tall-playlist .ar-playlist {
      max-height: 350px;
    }
  }

  &[data-mode="player"] {
    .ar-info {
      left: 0;
      right: 0;

      .ar-existing {
        text-decoration: none;
        // cursor: normal;

        // &::after {
        //   display: none;
        // }
      }
    }

    .ar-progress {
      left: 0;
    }

    .ar-playback-close,
    .ar-control {
      display: none;
    }
  }
}


@keyframes ar_beacon_glow {
  from {
    box-shadow: 0 0 10px 0 rgba(255, 0, 0, 0);
  }
  to {
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 1);
  }
}

@keyframes ar_stripe_motion {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50px 50px;
  }
}

// -dg-create/edit division
.dg-sheet-option-wrapper {
  label {
    position: relative;
  }
  &:hover {
    label {
      &:after {
        position: absolute;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 18px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        content: "\f05a";
        right: -25px;
        cursor: pointer;
      }
    }
  }
}

.sheet-detail-modal {
  .swal2-modal {
    width: 80vw;
    max-width: 900px;
  }
}

// -dg-customize sweet alert
.dg-confirm-container {
  .swal2-modal,
  .swal2-toast {
    .swal2-actions {
      button {
        i {
          font-size: 16px;
          margin-right: 7px;
        }
      }
    }
  }
}

.swal2-modal,
.swal2-toast {
  font-size: 14px !important;
}

// -dg-style-edit choirs, judges, rounds
li.choir.card.list-group-item {
  .director-div,
  .email-div,
  .phone-div {
    i {
      width: 24px;
      color: $cs-gray-mid-1;
    }
  }
  .director-div {
    margin-top: 10px;
  }
  .email-div,
  .phone-div {
    margin-top: 5px;
    span {
      color: $cs-gray-mid-2;
    }
  }
}

// -dg-style, create/edit captions
.create-edit-caption-content {
  position: relative;
  padding-bottom: 40px;
  form:first-child {
    & > div.form-group + div.form-group {
      display: none !important;
    }
    button {
      position: absolute;
      bottom: 0;
    }
  }
  & > ul > li {
    position: relative;
    &:hover {
      cursor: pointer;
    }
    &.dg-active {
      background: #7F409120;
      &::after {
        position: absolute;
        content: '\2713';
        font-size: 23px;
        right: 14px;
        top: 4px;
        color: #7F4091;
      }
    }
  }
}

// -dg-style add a judge modal
.dg-add-judge-form {
  .selectize-dropdown-content {
    max-height: 140px !important;
  }
}
// -dg-style add a choir modal
.dg-add-choir-form {
  .selectize-dropdown-content {
    max-height: 120px !important;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  float: right;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.enable-vote-span{
  float: left;
  margin-top: 6px;
}
.enable-vote-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  float: left;
}

.enable-vote-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
