

.board-list {
  width: 290px;
  background: #eee;
  padding: 0 10px;
  padding-bottom: 11px;
  overflow: hidden;
  float: left;
  margin-right: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  border-radius: 5px;

  .list-header {
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    padding-top: 13px;

    h3 {
      float: left;
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 5px;
    }

    .card-count {
      float: right;
      background: #ddd;
      padding: 0 5px;
      border-radius: 5px;
      min-width: 25px;
      text-align: center;
      line-height: 25px;
      font-size: 13px;
      margin-top: -4px;
      position: relative;
    }
  }

  ul.cards {
    // margin: 10px 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
    margin: 0 -8px;

    .card {
      padding: 10px;
      background: #fff;
      border-radius: 5px;
      -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
      -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
      margin: 10px 10px 12px;
      border: none;

      .dg-card-dec1 {
        width: 30px;
        height: 2px;
        margin: 4px auto 10px;
        background: #038fde;
      }

      &.choir {
        .name {
          font-size: 17px;
        }
        .actions {
          margin: 10px -10px -10px;
          padding: 7px 0;
          background: #038fde;
          &:hover {
            background: lighten(#038fde, 7);
          }
          a {
            color: white;
            i:before {
              margin-right: 4px;
            }
          }
        }
      }

      &.judge {
        padding-bottom: 0;
        .name {
          font-size: 17px;
        }
        .captions-group {
          display: block;
          float: none;
          margin: 0;
          margin-top: 6px;

          .caption {
            margin: 0 3px 3px 0;
            &:last-child {
              margin-bottom: 10px;
            }
          }
        }
        .actions {
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;
          margin: 10px -10px 0px;
          width: calc(100% + 20px);
          padding: 7px 0;
          background: #038fde;
          &:hover {
            background: lighten(#038fde, 7);
          }
          a {
            color: white;
            i:before {
              margin-right: 4px;
            }
          }
        }
      }

      &.round {
        .name {
          font-size: 17px;
        }
      }

    }
  }



  .add-resource,
  .import-resource,
  .change-password {
    display: block;
    background: #ddd;
    text-align: center;
    padding: 3px 10px;
    border-radius: 5px;
    color: #333;
    margin-top: 10px;
  }
}




#modal-cover {
  background: #444;
  opacity: .5;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#modal {
  z-index: 100;
  background: #fff;
  padding: 20px;
  position: fixed;
  margin: auto;
  top: 20px;
  left: 50px;
  right: 50px;
  width: 400px;
	overflow-y: scroll;
	max-height: 90%;
}
.add-resource-form-prototype,
.card-prototype,
.import-resource-form-prototype {
  display: none;
}

